import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select';
import Logo_Horizontal_purpleImage from '../../Assets/Icons/Logo_Horizontal_purple.png'
import Logo_Horizontal_WhiteImage from '../../Assets/Icons/Logo_Horizontal_White.png'

import { CompanyAddressDetails, CompanyDetails, CompanySecondPageProps, User } from "../../types";
import Helper from "../../utils/helper";

import { BsFillCheckCircleFill } from "react-icons/bs"
import { addressCountries, canadianProvinces, countries, customStyles, indianStates, usStates } from "../../utils/constant";

const CompanyDetailsSecondComponent = (props: CompanySecondPageProps) => {

    const initValue: CompanyDetails = {
        customerId: 1,
        country: "",
        currency: "",
        name: "",
        type: "",
        registrationNumber: "",
        VAT: "",
        EIN: "",
        taxId: "",
    };

    const [company, setCompany] = useState<CompanyDetails>(initValue);
    const [customer, setCustomer] = useState<User | null>(null);
    const navigate = useNavigate();
    const helper = Helper.getInstance();
    const location = useLocation();

    useEffect(() => {
        console.log(props.data);
        if (!props.data || !props.data.id) props.handlebackButton(true);
        else setCompany(props.data);

        if (location.state && location.state.customer) setCustomer(location.state.customer);
        if (location.state && location.state.company) setCompany(location.state.company);
    }, []);


    const initialValue: CompanyAddressDetails = {
        companyId: 1,
        country: '',
        state: '',
        city: '',
        address1: '',
        address2: '',
        zipCode: ''
    };

    const [address, setAddress] = useState<CompanyAddressDetails>(initialValue);
    const [loading, setLoading] = useState<boolean>(false);
    const [isZip, setIsZip] = useState<boolean>(true);

    const getStateArray = () => {
        if (!company || !company.country) return usStates;
        else if (company.country == 'CA') return canadianProvinces;
        else if (company.country == 'IN') return indianStates;
        else return usStates;
    }

    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        console.log(address);
        setLoading(true);
        try {
            if (isZip) {
                const addData = {
                    companyId: company.id,
                    country: company.country,
                    state: address.state,
                    city: address.city,
                    address1: address.address1,
                    address2: address.address2,
                    zipCode: address.zipCode
                }
                const res_data = await helper.postReq(helper.POST.ADD_COMPANY_ADDRESS_DETAILS, addData);
                const { data } = res_data.data;
                // if (data) navigate("/employer-congratulations", { replace: true, state: { addressId: data.id, customer } })
                // if (data) window.location.reload();
                if (data) navigate("/employer-dashboard", { replace: true, state: { addressId: data.id, customer } })
                else throw new Error("Failed");
            }
        } catch (error) {
            console.log(error);
            toast.error('Add Company Details Failed');
        }
        setLoading(false);
        // navigate("/employer-congratulations", { replace: true, state: { addressId: 1 } });
    };

    const handleBack = async () => {
        props.setCompany(company);
        props.handlebackButton(true)
    };

    const handleZip = async (val: string) => {
        const postalCodeRegex: RegExp = /^[0-9a-zA-Z\s]+$/;
        const isZip2 = postalCodeRegex.test(val);
        setIsZip(isZip2);

        setAddress(({
            ...address,
            zipCode: val
        }))
    };

    return (
        <>
            <section className='let-started'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='welcome-lets-started-container d-none d-sm-block d-print-block'>
                            <div className='container'>
                                <div className='cst-mobile-bg '>
                                    <div className='right-side-container'>
                                        {/* <h1 className='  owow-lets-started'>owow</h1> */}
                                        <img src={Logo_Horizontal_WhiteImage} className='img-fluid owow-lets-started' />

                                        <h1 className='welcome-lete-started' style={{ fontSize: "30px" }}>Welcome {customer?.name} 👋</h1>
                                        <p className="mt-4"><BsFillCheckCircleFill style={{ fontSize: "30px" }} /> Personal Details</p>
                                        <p className="mt-4 mb-4"><BsFillCheckCircleFill style={{ fontSize: "30px" }} /> Company Details</p>
                                        <h1 className='personal-details-heading' >Let’s get inside you company details</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='d-sm-block d-print-block cst-container-bg'>
                            {/* <h1 className='owow d-sm-none mb-4'>owow</h1> */}
                            <div className="text-center">
                                <img src={Logo_Horizontal_WhiteImage} className='img-fluid d-sm-none pt-4' />
                            </div>
                            <div className='cst-mobile-bg '>
                                <div className='right-side-container'>
                                    <h1 className='welcome-heading personal-details-heading-right-side'>Company details</h1>
                                    <h4 className='heado-para margin-fix-bottam'>We need your company details to shape your profile like a pro.</h4>
                                    <form name="addressForm" onSubmit={handleSubmit}>

                                        <div className="col-12  margin-fix-bottam">
                                            <label>Country</label>
                                            {/* <Select
                                            isDisabled={true}
                                            options={addressCountries.filter((ct) => ct.value == company?.country)[0]}
                                            defaultInputValue={addressCountries.filter((ct) => ct.value == company?.country)[0]}
                                            // options={addressCountries}
                                            // onChange={(ev) => setAddress({ ...address, country: ev?.value || '' })}
                                            styles={customStyles}
                                            className="mb-4"
                                        /> */}
                                            <input
                                                type="text"
                                                value={countries.filter((ct) => ct.value == company?.country)[0]?.label}
                                                className="form-control custom-input"
                                                placeholder="US"
                                                disabled
                                                readOnly
                                            />

                                        </div>

                                        <div className="col-12 margin-fix-bottam">

                                            <label>State*</label>
                                            <Select
                                                options={getStateArray()}
                                                onChange={(ev) => setAddress({ ...address, state: ev?.value || '' })}
                                                styles={customStyles}
                                                className="mb-4"
                                                required
                                                placeholder="Select"
                                            />
                                        </div>

                                        <div className="col-12 input-group-lg margin-fix-bottam">
                                            <label>Address line 1*</label>
                                            <input
                                                type="text"
                                                value={address.address1}
                                                onChange={(ev) => setAddress({ ...address, address1: ev.target.value })}
                                                className="form-control custom-input"
                                                placeholder="Street address (e.g., 123 Main St)"
                                                required
                                            />
                                        </div>

                                        <div className="col-12 input-group-lg margin-fix-bottam">
                                            <label>Address line 2(Optional)</label>
                                            <input
                                                type="text"
                                                value={address.address2}
                                                onChange={(ev) => setAddress({ ...address, address2: ev.target.value })}
                                                className="form-control custom-input"
                                                placeholder="Enter Here"
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-12 input-group-lg margin-fix-bottam">
                                                <label>City*</label>
                                                <input
                                                    type="text"
                                                    value={address.city}
                                                    onChange={(ev) => setAddress({ ...address, city: ev.target.value })}
                                                    className="form-control custom-input"
                                                    placeholder="Enter Here"
                                                    required
                                                />
                                                {/* <Select
                                                    options={[]}
                                                    // onChange={(ev) => setAddress({ ...address, city: ev?.value || '' })}
                                                    styles={customStyles}
                                                    className="mb-4"
                                                /> */}
                                            </div>
                                            <div className="col-md-6 col-12 input-group-lg margin-fix-bottam">
                                                <label>Postal Code/Zip Code*</label>
                                                <input
                                                    type="number"
                                                    value={address.zipCode}
                                                    onChange={(ev) => handleZip(ev.target.value)}
                                                    className="form-control custom-input"
                                                    placeholder="e.g., 12345"
                                                    required
                                                />
                                                {!isZip && <p style={{ color: 'red', fontSize: 'medium' }}>Invalid Postal Code/Zip Code </p>}
                                            </div>
                                        </div>

                                        <div className="container col-12 text-center margin-fix-bottam pb-5" style={{ display: "flex", gap: "20px", justifyContent: 'space-evenly' }}>
                                            <button
                                                type="button"
                                                className='btn btn-outline create-account-btn-outline p-3 col-6'
                                                style={{ fontSize: "20px" }}
                                                onClick={() => handleBack()}
                                            >Back
                                            </button>
                                            <button
                                                type="submit"
                                                className='btn create-account-btn p-3 col-6'
                                                style={{ fontSize: "20px" }}
                                                disabled={loading}
                                            >{loading ? 'Finishing Up...' : 'Submit'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CompanyDetailsSecondComponent