import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import OwowLogo from '../../Assets/Icons/Logo_Horizontal_purple.png';
import { FaArrowRight } from "react-icons/fa";
import { MdErrorOutline } from "react-icons/md";
import { FiClock } from "react-icons/fi";
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import Helper from '../../utils/helper';

import startRecLogo from '../../Assets/Icons/startRecording.png';
import stopRecLogo from '../../Assets/Icons/stopRecording.png';
import { GPTQuestion, skData } from '../../types';
import { SKILL_TYPES } from '../../utils/constant';
import LottieAnimation from '../../Components/LottieAnimation';

const pythonServiceBaseUrl = process.env.REACT_APP_GPT_SERVER_BASE_URL || '';
const WS_URL = process.env.REACT_APP_GPT_SERVER_SOCKET_URL || '';


const EmployeeNewGPTVetting = () => {

    const location = useLocation();
    const { user } = useAuth();
    const navigate = useNavigate();
    const helper = Helper.getInstance();

    const [questions, setQuestions] = useState<GPTQuestion[]>([]);
    const [assesmentId, setAssesmentId] = useState<number>(); // Timer state
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [progress, setProgress] = useState(0);
    const [transcripts, setTranscripts] = useState<string[]>([]);
    const [ws, setWs] = useState<WebSocket | null>(null);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    // const [mediaRecognition, setMediaRecognition] = useState<any>(null);
    const [isRecording, setIsRecording] = useState(false);
    const [transcript, setTranscript] = useState<string>('');
    const [timer, setTimer] = useState<number>(0); // Timer state
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
    const [loading2, setLoading2] = useState<boolean>(true);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const prevUser = useRef<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [streamData, setStreamData] = useState<MediaStream | null>();
    const [audioData, setAudioData] = useState<any>();
    const [isGetResult, setIsGetResult] = useState<boolean>(false);
    const [isGetResult2, setIsGetResult2] = useState<boolean>(false);
    const isGetResultRef = useRef(isGetResult);

    useEffect(() => {
        isGetResultRef.current = isGetResult;
    }, [isGetResult]);

    useEffect(() => {

        const getQuestions = async (skills: skData[]) => {
            try {
                const reqBody = {
                    coreSkills: skills.filter((sk) => sk.skillType == SKILL_TYPES.MAIN),
                    additionalSkills: skills.filter((sk) => sk.skillType == SKILL_TYPES.ADDITIONAL)
                }
                const response = await axios.post(`${pythonServiceBaseUrl}/generate_questions`, reqBody);
                if (response.data && response.data.questions) {
                    const noQueRemoved = response.data.questions.filter((q: GPTQuestion) => q.text !== '');
                    // const threeQuestionsOnly = [noQueRemoved[0], noQueRemoved[1], noQueRemoved[2]];
                    // stopRecording(false);
                    setQuestions(noQueRemoved);
                    setAssesmentId(response.data.assesmentId || 1);
                    console.log("Get Questions Media Recorder : ", mediaRecorder?.state)
                    playAudioInBackground(noQueRemoved[0].audio_url);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                speak("Failed to Load your Assesment, Please Try Again", false);
                toast.error("Failed to Load your Assesment, Please Try Again");
                console.error('Error fetching questions:', error);
            }
        };

        // console.log("First UseEffect")

        if (!location.state || !location.state.skills || !location.state.skills.length) {
            navigate('/candidate-gpt-vetting-page', { replace: true });
        } else if (user && user !== prevUser.current && ws && mediaRecorder) {
            console.log("Getting Questions")
            getQuestions(location.state.skills);
            prevUser.current = user;
        }
    }, [user, ws, mediaRecorder]);

    // Initialize MediaRecorder and handle audio data
    useEffect(() => {
        // Request access to the microphone
        window.navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream: MediaStream) => setStreamData(stream))
            .catch(error => {
                setStreamData(null);
                console.error('Error accessing media devices.', error);
            });
    }, []);

    useEffect(() => {
        if (!ws) setWs(new WebSocket(WS_URL));
    }, [ws]);

    useEffect(() => {
        if (ws) {
            ws.onopen = () => {
                console.log('WebSocket connection established');
            };

            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log("Socket Response..........................");
                // console.log(data);
                if (data.transcription) {
                    // setTranscript(data.transcription);
                    setTranscripts((prevTranscripts) => [...prevTranscripts, data.transcription]);

                } else {
                    setTranscripts((prevTranscripts) => [...prevTranscripts, '']);
                }

                //  console.log(currentQuestionIndexRef.current);
                // console.log(questionsLengthRef.current);
                // console.log(currentQuestionIndexRef.current === questionsLengthRef.current - 3);

                if (isGetResultRef.current) setIsGetResult2(true);
                //         setTranscript((prev) => prev + data.transcription + ' ');
                //         setTranscripts([...transcripts, transcript]);
                //     }
            };

            ws.onclose = () => {
                console.log('WebSocket connection closed, attempting to reconnect...');
                setTimeout(() => setWs(null), 5000); // Retry after 5 seconds
            };

            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
            };
        }
    }, [ws]);

    useEffect(() => {
        if (isGetResult2) getResult();
    }, [isGetResult2])

    useEffect(() => {
        if (streamData) {
            setMediaRecorder(new MediaRecorder(streamData));
            console.log("New Object for Media Recorder&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&")
            setLoading2(false);
        };
    }, [streamData]);

    useEffect(() => {
        if (mediaRecorder) {
            mediaRecorder.ondataavailable = (event) => {
                // console.log("Data Available...............");
                // console.log(event);
                setAudioData(event);

            }

            mediaRecorder.onerror = (event: any) => {
                console.log("mediaRecorder.onerror => On Error");
                console.log(event);
            };

            mediaRecorder.onstop = (event: any) => {
                console.log("mediaRecorder.onstop => On Stop");
                console.log(event);
            };

            mediaRecorder.onstart = (event: any) => {
                console.log("mediaRecorder.onstart => On Start");
                console.log(event);
            };
        }
    }, [mediaRecorder]);

    useEffect(() => {
        console.log("Inside Socket Data Send......")
        if (audioData) ws?.send(audioData.data);
        // else if (mediaRecorder && mediaRecorder.state === 'recording') {
        //     mediaRecorder.requestData();
        // }

    }, [mediaRecorder, audioData])

    useEffect(() => {
        if (intervalId) clearInterval(intervalId); // Clear any existing interval

        if (questions.length) {
            const id = setInterval(() => {
                setTimer((prev) => {
                    if (prev >= 120) {
                        stopRecording(); // Stop the timer after 2 minutes
                        return 120;
                    }
                    return prev + 1;
                });
            }, 1000); // Update timer every second

            setIntervalId(id);

            return () => clearInterval(id);
        }
        // Cleanup interval on unmount
    }, [questions, currentQuestionIndex]);

    const toggleRecording = () => {
        if (isRecording) {
            stopRecording();
        }
        // else {
        //     startRecording();
        // }
    };

    useEffect(() => {
        if (questions.length && !isSpeaking && currentQuestionIndex == 0 && mediaRecorder && mediaRecorder.state !== 'recording') {
            console.log("Useffect for Inactive Media Recorder!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
            mediaRecorder.start();
        }
    }, [currentQuestionIndex, mediaRecorder, isSpeaking, questions]);

    const startRecording = () => {
        // console.log("startRecording -> mediaRecorder.state : ", mediaRecorder?.state)
        setIsRecording(false);
        if (mediaRecorder && mediaRecorder.state === 'inactive') {
            mediaRecorder.start();
            // console.log("startRecording in IF Loop -> mediaRecorder.state : ", mediaRecorder?.state)
            // console.log("startRecording in IF333 Loop -> mediaRecorder.state : ", mediaRecorder?.state)
        } else {
            console.log("In Else of Start Recording###################################")
        }
        setIsRecording(true);

        // if (mediaRecognition) mediaRecognition.start();
        // setTranscript('');
    };

    const stopRecording = (goNext: boolean = true) => {
        console.log("stopRecording -> mediaRecorder.state : ", mediaRecorder?.state)
        if (mediaRecorder && mediaRecorder.state === 'recording') {
            mediaRecorder.stop();
            // if (ws) ws.send(JSON.stringify({ event: 'stop_recording' }));
        }
        // if (mediaRecognition) mediaRecognition.stop();
        setIsRecording(false);
        // Clear the timer when recording stops
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
        setTimer(0);
        if (goNext) handleNextButton();
    };

    const handleNextButton = () => {
        setProgress((prev) => prev + 100 / questions.length);
        // if (currentQuestionIndex == 0) setTranscripts(['']);
        setIsRecording(false);

        if (currentQuestionIndex < questions.length - 1) {
            playAudioInBackground(questions[currentQuestionIndex + 1].audio_url);
            setCurrentQuestionIndex((prev) => prev + 1);
        } else {
            setLoading(true);
            setIsSpeaking(true);
            setIsGetResult(true);
            // setCurrentQuestionIndex((prev) => prev + 1);
            // getResult();
            // skipResultFlow();
        }
    };

    const skipResultFlow = () => {
        try {
            ws?.close();
        } catch (error) {
            console.log(error);
        }
        setTimeout(() => {
            navigate('/candidate-gpttest-end', { replace: true, state: { result: true } });
        }, 100);
    };

    const getResult = async () => {
        try {
            setLoading(true);
            const skills: skData[] = location.state.skills;

            const response = await axios.post(`${pythonServiceBaseUrl}/generate_result`, {
                employeeId: user?.id || 1,
                questions: questions.map((q: GPTQuestion) => q.text),
                answers: transcripts,
                coreSkills: (skills.filter((sk: skData) => sk.skillType == SKILL_TYPES.MAIN)).map((el) => el.skill),
                additionalSkills: (skills.filter((sk: skData) => sk.skillType == SKILL_TYPES.ADDITIONAL)).map((el) => el.skill)
                // assesmentId
            });
            if (response.data) skipResultFlow();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("Failed to Generate Result, Please Try Again");
            console.error('Failed to Generate Result: ', error);
        }
    };

    // const saveTest = async (result: number) => {
    //     setLoading2(true);
    //     const bData = {
    //         skill: location.state.skill,
    //         experience: Number(location.state.exp),
    //         queAnswers: questions,
    //         type: Number(location.state.type),
    //         result
    //     };

    //     try {
    //         const res_data = await helper.postReq(helper.POST.CREATE_RESULT, bData);
    //         const { data } = res_data.data;

    //         if (data) navigate('/candidate-gpttest-end', { replace: true, state: { result: true } });
    //         setLoading2(false);
    //     } catch (error) {
    //         console.log(error);
    //         setLoading2(false);
    //     }
    // };

    // const speak = (data: string, isQuestion: boolean = true) => {
    //     if ('speechSynthesis' in window) {
    //         const textData = isQuestion ? `Question Number ${data}` : data
    //         const utterance = new SpeechSynthesisUtterance(textData);
    //         window.speechSynthesis.speak(utterance);
    //     } else {
    //         toast.error('Sorry, your browser does not support text-to-speech.');
    //     }
    // };

    const speak = (text: string, isQuestion: boolean = true): void => {
        // if (isQuestion) setTranscript('');
        // const textData = isQuestion ? `Question Number ${text}` : text
        const utterance = new SpeechSynthesisUtterance(text);

        // Get the list of available voices
        const voices = window.speechSynthesis.getVoices();

        // Find a female, human-like voice (this may vary by browser)
        // console.log(voices);
        const femaleVoice = voices.find(voice =>
            voice.name.includes('Google UK English Female') ||
            voice.name.includes('Google US English Female') ||
            voice.name.includes('Microsoft Zira Desktop')
            // (voice.gender === 'female' && voice.localService)
        );

        // Set the selected voice
        if (femaleVoice) {
            utterance.voice = femaleVoice;
        }

        // Set additional options
        utterance.pitch = 1.2;  // Slightly higher pitch for a more natural female voice
        utterance.rate = 1;     // Normal speaking rate
        utterance.volume = 1;   // Full volume

        // Speak the text
        // setIsSpeaking(true);
        // Re-enable the button when the speech ends
        // utterance.onend = () => {
        //     setIsSpeaking(false);
        //     if (isQuestion) startRecording();
        // };

        window.speechSynthesis.speak(utterance);
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [transcripts.length]);


    const handleCopy = (event: any) => {
        event.preventDefault();
        toast.error('Copying text is not allowed.');
        speak('Copying text is not allowed', false);
    };

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                speak("Please don't switch tabs or minimize the window!", false);
                alert("Please don't switch tabs or minimize the window!");
                // You can also add additional logic here, such as logging out the user
            }
        };

        const handleBlur = () => {
            alert("Please stay on this tab!");
            speak("Please stay on this tab!", false);
            // You can add additional logic here as well
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        // window.addEventListener('blur', handleBlur);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            // window.removeEventListener('blur', handleBlur);
        };
    }, []);

    const playAudioInBackground = (audioUrl: string): void => {
        setIsSpeaking(true);
        const audio = new Audio(audioUrl);
        audio.play();
        audio.addEventListener('ended', () => {
            setTimeout(() => {
                // console.log('mediaRecorder.state in playAudioInBackground : ', mediaRecorder?.state)
                startRecording();
                setIsSpeaking(false);
            }, 100)
        });
    };

    const playAudioInBackground22 = (audioUrl: string): void => {
        setIsSpeaking(true);
        const audio = new Audio(audioUrl);
        audio.play();
        audio.addEventListener('ended', () => {
            setTimeout(() => {
                // console.log('mediaRecorder.state in playAudioInBackground : ', mediaRecorder?.state)
                mediaRecorder?.start();
                mediaRecorder?.stop();
                startRecording();
                setIsSpeaking(false);
            }, 100)
        });
    };

    return (
        <section className='main-ashonrd-test'>
            {loading ? <LottieAnimation /> : (
                <div className='container'>
                    <div className='text-center pt-3'>
                        <img src={OwowLogo} className='img-fluid' alt="Owow Logo" />
                    </div>
                    <div className="row mt-3">
                        <div className="col-xl-8 flex-column d-flex grid-margin stretch-card">
                            <div className="row flex-grow">
                                <div className="col-sm-12 grid-margin stretch-card">
                                    <div className="card card-cst-new-dsh">
                                        <div className="card-header card-header-cst">
                                            {questions.length > 0 && <div className='d-flex justify-content-between align-items-center'>
                                                <strong>Question : {currentQuestionIndex + 1}</strong>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div style={{ width: '100px', backgroundColor: 'lightgray', height: '6px', position: 'relative' }}>
                                                        <div className='dyna-pro-bar-cst' style={{ width: `${progress}%`, height: '100%', backgroundColor: '#492A99', position: 'absolute' }}></div>
                                                    </div>
                                                    <strong>&nbsp;{progress}%</strong>
                                                </div>
                                                <strong><FiClock /> {Math.floor(timer / 60)}:{('0' + (timer % 60)).slice(-2)}</strong>
                                            </div>}
                                        </div>
                                        <div className="card-body card-body-cst">
                                            <div className="card card-cst-dashbord ">
                                                <div className="card-body cst-card-body">
                                                    <h4 className="text-center" onCopy={handleCopy}>{questions[currentQuestionIndex].text}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 stretch-card mt-4">
                                    <div className="card card-cst-new-dsh">
                                        <div className="card-header card-header-cst">
                                            <strong>Record Your Answer</strong>
                                        </div>
                                        <div className="card-body card-body-cst">
                                            <div className="card card-cst-dashbord ">
                                                <div className="card-body cst-card-body text-center">
                                                    {/* <button
                                                    className={`btn ${isRecording ? 'btn-danger' : 'btn-primary'}`}
                                                    onClick={toggleRecording}
                                                >
                                                    {isRecording ? 'Stop Recording' : 'Start Recording'}
                                                </button> */}
                                                    {questions.length > 0 && <img
                                                        src={isRecording ? stopRecLogo : startRecLogo}
                                                        onClick={toggleRecording}
                                                        alt={isRecording ? 'Stop Recording' : 'Start Recording'} className='img-fluid'
                                                        style={{ maxHeight: '150px', maxWidth: '150px', cursor: 'pointer' }}
                                                    />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 d-flex grid-margin stretch-card">
                            <div className="card card-cst-new-dsh">
                                <div className="card-header card-header-cst">
                                    <div className='d-flex justify-content-between'>
                                        <strong>Interview Transcript</strong>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={() => console.log("A")}
                                                checked
                                                role="switch"
                                                id="flexSwitchCheckChecked"
                                            />
                                            <label className="form-check-label">Auto Scroll</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body card-body-cst">
                                    <div className="chat-content-scroll-area scrollbar scroller">
                                        {/* {transcript && (
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className='flex-1 d-flex justify-content-start mb-2'>
                                                    <div className="chat-message-left p-3">
                                                        <h6>AI Interviewer</h6>
                                                        <div>{questions[currentQuestionIndex]}</div>
                                                    </div>
                                                </div>
                                                <div className='flex-1 d-flex justify-content-end mb-2'>
                                                    <div className="chat-message-right p-3">
                                                        <h6>{user?.name || 'Candidate'}</h6>
                                                        <div>{transcript}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )} */}
                                        {transcripts.map((transcriptData, index) => (
                                            <div className="row" key={index}>
                                                <div className="col-sm-12">
                                                    <div className='flex-1 d-flex justify-content-start mb-2'>
                                                        <div className="chat-message-left p-3">
                                                            <h6>AI Interviewer</h6>
                                                            <div>{questions[index].text}</div>
                                                        </div>
                                                    </div>
                                                    <div className='flex-1 d-flex justify-content-end mb-2'>
                                                        <div className="chat-message-right p-3">
                                                            <h6>{user?.name || 'Candidate'}</h6>
                                                            <div>{transcriptData}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div ref={messagesEndRef} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-8 col-12 mt-4 pb-3'>
                        <div className='px-3'>
                            <div className="d-flex flex-wrap align-items-center justify-content-md-between justify-content-center">
                                <h6 className='align-items-center mb-2'>
                                    <MdErrorOutline style={{ color: 'red' }} />
                                    Please do not refresh the page or you will lose your recorded data
                                </h6>
                                <button
                                    className='btn create-account-btn-cst px-5 d-flex align-items-center justify-content-center mt-3 mt-md-0'
                                    onClick={() => stopRecording()}
                                    disabled={isSpeaking}
                                >
                                    {currentQuestionIndex == questions.length - 1 ? 'Submit' : 'Next'} <FaArrowRight className='ml-2' />
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            )}
        </section>
    );
};

export default EmployeeNewGPTVetting;
